<template>
  <div>
    <TButton
      content="Create contract"
      icon="cil-folder-plus"
      class="mb-3 px-4"
      :to="lodash.getReferenceLink('contract','new')"
      :messageOptions="{ bold: true }"
    />
    <TTableAdvance
      :items="contracts"
      :fields="fields"
      store="order.contracts"
      @click-clear-filter="clearFilter"
      reloadable
      @click-reload="refresh"
      resource="contracts"
      enterable
      deletable
    >
      <template #status_id="{ item }">
        <td>
          <SMessageContractStatus :status="item.status_id" />
        </td>
      </template>
      <template #customer="{ item }">
        <td>
          <TLink
            :content="item.customer ? item.customer.name : item.customer_id"
            :to="lodash.getReferenceLink('user',item.customer_id)"
          />
        </td>
      </template>
      <template #service_fee="{ item }">
        <td>
          <TMessageMoney
            :amount="item.service_fee"
            :currency="item.service_fee_currency_id"
          />
        </td>
      </template>
      <template #purchase_cost_outstanding="{ item }">
        <td>
          <TMessageMoney
            :amount="item.purchase_cost_outstanding"
            :currency="item.purchase_cost_currency_id"
          />
        </td>
      </template>
      <template #transport_organization_id="{ item }">
        <td>
          <SMessageOrganization :id="item.transport_organization_id" />
        </td>
      </template>
      <template #sales_organization_id="{ item }">
        <td>
          <SMessageOrganization :id="item.sales_organization_id" />
        </td>
      </template>
      <template #service_fee_outstanding="{ item }">
        <td>
          <TMessageMoney
            :amount="item.service_fee_outstanding"
            :currency="item.service_fee_currency_id"
          />
        </td>
      </template>
      <template #updated_at="{ item }">
        <td>
          <TMessageDateTime :content="item.updated_at" small />
        </td>
      </template>
      <template #id-filter>
        <TInputText
          :value.sync="filter.id"
          @update:value="filterChange"
          placeholder="Contract Id"
        />
      </template>
      <template #status_id-filter>
        <SSelectContractStatus
          :value.sync="filter.status_id"
          @change="filterChange"
        />
      </template>
      <template #transport_organization_id-filter>
        <SSelectOrganization
          :value.sync="filter.transport_organization_id"
          @change="filterChange"
        />
      </template>
      <template #sales_organization_id-filter>
        <SSelectOrganization
          :value.sync="filter.sales_organization_id"
          @change="filterChange"
        />
      </template>
      <template #customer-filter>
        <SSelectCustomer
          :value.sync="filter.customer_id"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
      <template #updated_at-filter>
        <TInputDateTimeRange
          :value.sync="dateRange"
          @update:value="setUpdatedAtFilter"
        />
      </template>
    </TTableAdvance>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px; max-width: 40px" },
        {
          key: "id",
          label: "Contract Id",
          _classes: "text-nowrap",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "status_id",
          label: "Status",
          _classes: "text-nowrap",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "customer",
          label: "Customer",
          _classes: "text-nowrap",
          _style: "width: 270px; min-width: 270px",
        },
        {
          key: "transport_organization_id",
          label: "Transportation company",
          _classes: "text-nowrap",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "sales_organization_id",
          label: "Purchasing company",
          _classes: "text-nowrap",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "service_fee",
          label: "Service fee",
          _classes: "text-nowrap",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "service_fee_outstanding",
          label: "Debt service fee",
          _classes: "text-nowrap",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "purchase_cost_outstanding",
          label: "Debt for goods",
          _classes: "text-nowrap",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "updated_at",
          label: "Updated at",
          sorter: true,
          _style: "width: 220px",
        },
      ],
      filter: {},
      filter_between: {},
      dateRange: {
        start: null,
        end: null,
      },
    };
  },
  mounted() {
    this.$store.dispatch("order.contracts.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      contracts: "order.contracts.list",
    }),
  },
  methods: {
    refresh() {
      this.$store.dispatch("order.contracts.fetch");
    },
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({ ...filter, ...filterBetween });
      this.$store.dispatch("order.contracts.apply-query", filterFields);
    },
    setUpdatedAtFilter({ start, end }) {
      this.filter_between["updated_at"] = `${start},${end}`;
      this.filterChange();
    },
    clearFilter() {
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filter = {};
      this.filter_between = {};
      this.filterChange();
    },
  },
};
</script>
